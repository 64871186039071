<template>
  <div class="transport-map">
    <l-map
        :zoom="zoom"
        :center="[GET_STOP.lat, GET_STOP.lon]"
        :options="{attributionControl: false, zoomControl: false}"
        :crs="crs"
        ref="map"
    >
      <l-tile-layer
          :url="url"
          :attribution="attribution"
      />

      <StationMarker v-for="busStation in GET_STATIONS"
                     :key="busStation.id"
                     :station="busStation"
                     :currentStationId="panelStationId"
      ></StationMarker>

      <l-marker :lat-lng="panelLocation"
                :icon="icon"></l-marker>

      <l-control-attribution :position="'bottomright'" :prefix="false" />

      <l-control class="example-custom-control" :position="'bottomright'">
        <MapControls
            @zoomin="zoomIn"
            @zoomout="zoomOut"
            @setlocation="locateCenter"
        />
      </l-control>
    </l-map>
  </div>
</template>

<script>
import L, { CRS } from 'leaflet';
import { LControlAttribution, LMap, LTileLayer, LControl, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import {mapGetters} from "vuex";
import StationMarker from "@/components/TransportMap/StationMarker/StationMarker";
import MapControls from "@/components/TransportMap/MapControls/MapControls";

export default {
  name: "TransportMap",

  components: {
    MapControls,
    LMap,
    LTileLayer,
    LControl,
    LMarker,
    LControlAttribution,
    StationMarker
    //LIcon,
    //LPopup
  },

  mounted() {

  },

  computed: {
    ...mapGetters(['GET_STATIONS', 'GET_STOP']),

    panelLocation() {
      return [this.GET_STOP?.lat, this.GET_STOP?.lon];
    },

    panelStationId() {
      return this.GET_STOP?.id;
    }
  },

  data() {
    return {
      url: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.07.28-0-b210701140430&x={x}&y={y}&z={z}&scale=2&lang=ru_RU',
      attribution:
          `
          <div style="display: flex; align-items: center; font-size: 11px;">© Яндекс
          <a style="margin-left: 4px; color: black; font-size: 11px;" href='https://yandex.ru/legal/maps_termsofuse/?lang=ru'>Условия использования</a>
          <img style="margin: 0 4px; height: 14px;" src="/img/map/yandex-logo.svg"/></div>
        `,
      crs: CRS.EPSG3395,
      zoom: 14,
      icon: L.icon({
        iconUrl: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiByeD0iMTYiIGZpbGw9IiM0NDgwRjMiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDlDOCA4LjQ0NzcyIDguNDQ3NzIgOCA5IDhIMjFDMjIuNjU2OSA4IDI0IDkuMzQzMTUgMjQgMTFWMTIuNUMyNCAxMy4wNTIzIDIzLjU1MjMgMTMuNSAyMyAxMy41VjE1LjVDMjMuNTUyMyAxNS41IDI0IDE1Ljk0NzcgMjQgMTYuNVYyMUMyNCAyMS41NTIzIDIzLjU1MjMgMjIgMjMgMjJIMTMuODI5M0MxMy40MTc1IDIzLjE2NTIgMTIuMzA2MiAyNCAxMSAyNEM5LjM0MzE1IDI0IDggMjIuNjU2OSA4IDIxQzggMTkuMzQzMSA5LjM0MzE1IDE4IDExIDE4QzEyLjMwNjIgMTggMTMuNDE3NSAxOC44MzQ4IDEzLjgyOTMgMjBIMTdWMTNDMTcgMTIuNDQ3NyAxNy40NDc3IDEyIDE4IDEySDE5QzE5LjU1MjMgMTIgMjAgMTIuNDQ3NyAyMCAxM1YyMEgyMlYxNy41QzIxLjQ0NzcgMTcuNSAyMSAxNy4wNTIzIDIxIDE2LjVWMTIuNUMyMSAxMS45NDc3IDIxLjQ0NzcgMTEuNSAyMiAxMS41VjExQzIyIDEwLjQ0NzcgMjEuNTUyMyAxMCAyMSAxMEg5QzguNDQ3NzIgMTAgOCA5LjU1MjI4IDggOVpNMTEgMjBDMTEuNTUyMyAyMCAxMiAyMC40NDc3IDEyIDIxQzEyIDIxLjU1MjMgMTEuNTUyMyAyMiAxMSAyMkMxMC40NDc3IDIyIDEwIDIxLjU1MjMgMTAgMjFDMTAgMjAuNDQ3NyAxMC40NDc3IDIwIDExIDIwWiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTEyIDEzQzEyIDEyLjQ0NzcgMTIuNDQ3NyAxMiAxMyAxMkgxNUMxNS41NTIzIDEyIDE2IDEyLjQ0NzcgMTYgMTNWMTVDMTYgMTUuNTUyMyAxNS41NTIzIDE2IDE1IDE2SDEzQzEyLjQ0NzcgMTYgMTIgMTUuNTUyMyAxMiAxNVYxM1oiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xMSAxM0MxMSAxMi40NDc3IDEwLjU1MjMgMTIgMTAgMTJIOFYxNkgxMEMxMC41NTIzIDE2IDExIDE1LjU1MjMgMTEgMTVWMTNaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4=',
        iconSize: [32, 32],
        iconAnchor: [16, 16]
      }),
    }
  },

  methods: {
    zoomIn() {
      const mapComponent = this.$refs.map;
      mapComponent.mapObject.zoomIn();
      this.zoom = mapComponent.mapObject.getZoom();
    },

    zoomOut() {
      const mapComponent = this.$refs.map;
      mapComponent.mapObject.zoomOut();
      this.zoom = mapComponent.mapObject.getZoom();
    },

    locateCenter() {
      const mapComponent = this.$refs.map;
      mapComponent.mapObject.setView(this.panelLocation, 14);
    }
  }
}
</script>

<style lang="scss">
.transport-map {
  height: 276px;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;

}
.leaflet-tile-pane {
  //filter: invert(0.8) hue-rotate(-190deg);
}
.leaflet-marker-icon {
  filter: drop-shadow(0px 4px 16px rgba(12, 16, 24, 0.12));
}
</style>
