<template>
  <l-marker
      v-if="currentStationId !== station.id"
      :lat-lng="coordinates"
      :icon="icon"
      :zIndexOffset="500">
    <l-popup>
      <div class="popup__title roquefort harakiri--text">
        {{ station.title }}
      </div>
      <div class="сaprino troy--text">
        {{ $t('transport.station') }}
      </div>
    </l-popup>
  </l-marker>
</template>

<script>
import { icon } from 'leaflet';
import { LMarker, LPopup } from 'vue2-leaflet';

export default {
  name: "StationMarker",

  props: {
    station: {
      type: Object,
      default: () => {}
    },
    currentStationId: {
      type: Number,
      default: () => []
    }
  },

  components: {
    LMarker,
    LPopup
  },

  computed: {
    coordinates() {
      return [this.station?.lat, this.station?.lon];
    },
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    }
  },

  data() {
    return {
      iconSize: [ 16, 16 ],
      iconAnchor: [ 8, 8 ],
      icon: icon({
        iconUrl: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiByeD0iMTIiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNCA1QzQgNC40NDc3MiA0LjQ0NzcyIDQgNSA0SDE3QzE4LjY1NjkgNCAyMCA1LjM0MzE1IDIwIDdWOC41QzIwIDkuMDUyMjggMTkuNTUyMyA5LjUgMTkgOS41VjExLjVDMTkuNTUyMyAxMS41IDIwIDExLjk0NzcgMjAgMTIuNVYxN0MyMCAxNy41NTIzIDE5LjU1MjMgMTggMTkgMThIOS44MjkyOUM5LjQxNzQ2IDE5LjE2NTIgOC4zMDYyMiAyMCA3IDIwQzUuMzQzMTUgMjAgNCAxOC42NTY5IDQgMTdDNCAxNS4zNDMxIDUuMzQzMTUgMTQgNyAxNEM4LjMwNjIyIDE0IDkuNDE3NDYgMTQuODM0OCA5LjgyOTI5IDE2SDEzVjlDMTMgOC40NDc3MiAxMy40NDc3IDggMTQgOEgxNUMxNS41NTIzIDggMTYgOC40NDc3MiAxNiA5VjE2SDE4VjEzLjVDMTcuNDQ3NyAxMy41IDE3IDEzLjA1MjMgMTcgMTIuNVY4LjVDMTcgNy45NDc3MiAxNy40NDc3IDcuNSAxOCA3LjVWN0MxOCA2LjQ0NzcyIDE3LjU1MjMgNiAxNyA2SDVDNC40NDc3MiA2IDQgNS41NTIyOCA0IDVaTTcgMTZDNy41NTIyOCAxNiA4IDE2LjQ0NzcgOCAxN0M4IDE3LjU1MjMgNy41NTIyOCAxOCA3IDE4QzYuNDQ3NzIgMTggNiAxNy41NTIzIDYgMTdDNiAxNi40NDc3IDYuNDQ3NzIgMTYgNyAxNloiIGZpbGw9IiM0NDgwRjMiLz4KPHBhdGggZD0iTTggOUM4IDguNDQ3NzIgOC40NDc3MiA4IDkgOEgxMUMxMS41NTIzIDggMTIgOC40NDc3MiAxMiA5VjExQzEyIDExLjU1MjMgMTEuNTUyMyAxMiAxMSAxMkg5QzguNDQ3NzIgMTIgOCAxMS41NTIzIDggMTFWOVoiIGZpbGw9IiM0NDgwRjMiLz4KPHBhdGggZD0iTTcgOUM3IDguNDQ3NzIgNi41NTIyOCA4IDYgOEg0VjEySDZDNi41NTIyOCAxMiA3IDExLjU1MjMgNyAxMVY5WiIgZmlsbD0iIzQ0ODBGMyIvPgo8L3N2Zz4=',
        iconSize: this.dynamicSize,
        iconAnchor: this.dynamicAnchor
      }),
    }
  }
}
</script>

<style lang="scss">
.popup {
  &__title {
    margin-bottom: 4px;
  }
}
</style>
