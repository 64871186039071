<template>
  <div>
    <r-button @click="changeToDark" title="Change Dark"></r-button>
    <r-button @click="changeToDefault" title="Change Default"></r-button>

    <r-row wrap :row-gap="{ widest: 24, wide: 20, middle: 16, narrow: 12 }">
      <r-col :cols="{ widest: 4, wide: 4, middle: 12, narrow: 12 }">
        <TransportMap />
      </r-col>
    </r-row>
  </div>
</template>

<script>
import TransportMap from "@/components/TransportMap/TransportMap";

export default {
  name: "TransportScreen",
  components: {TransportMap},
  methods: {
    changeToDark() {
      this.$rir.changeTheme('dark');
    },
    changeToDefault() {
      this.$rir.changeTheme('default');
    },
  },
}
</script>

<style lang="scss">

</style>
